.app-header {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 64px;
    justify-content: space-between;

    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #ffffff13;
}

.app-logo {
    height: 2.3em;
}

.app-content-body {
    margin-top: 64px;
    padding: 1.5em;
}

.app-footer {
    text-align: center;
    padding-left: 2em;
    padding-right: 2em;
}